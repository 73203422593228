import { useToast } from 'vuestic-ui'

export function useToaster() {
  const toaster = useToast()

  const success = (message: string) => {
    return toaster.init({
      message,
      color: 'success',
      position: 'bottom-right',
    })
  }

  const error = (message: string) => {
    return toaster.init({
      message,
      color: 'danger',
      position: 'bottom-right',
    })
  }

  const warning = (message: string) => {
    return toaster.init({
      message,
      color: 'warning',
      position: 'bottom-right',
    })
  }

  const info = (message: string) => {
    return toaster.init({
      message,
      color: 'primary',
      position: 'bottom-right',
    })
  }

  return { success, error, warning, info }
}
