import { getAuthHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import { AccessTemplate } from '@/modules/techdemo/model/access-template.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { techdemoApiUrl } from '@/modules/techdemo/api/_shared'

export async function getAccessTemplate(id: string): Promise<AccessTemplate> {
  const accessTemplate = await simpleAuthorizedQuery<AccessTemplate>(techdemoApiUrl(`admin/access-templates/${id}`))

  return new AccessTemplate(accessTemplate)
}

export async function getAllAccessTemplates(): Promise<AccessTemplate[]> {
  const accessTemplates = await simpleAuthorizedQuery<AccessTemplate[]>(techdemoApiUrl('admin/access-templates'))

  return accessTemplates.map((accessTemplate) => new AccessTemplate(accessTemplate))
}

export async function updateAccessTemplate(accessTemplate: AccessTemplate): Promise<AccessTemplate> {
  return axios
    .patch(techdemoApiUrl(`admin/access-templates/${accessTemplate._id}`), accessTemplate, {
      headers: getAuthHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AccessTemplate(data))
    .catch(apiErrorCatcher)
}

export async function deleteAccessTemplate(accessTemplate: AccessTemplate): Promise<unknown> {
  return axios
    .delete(techdemoApiUrl(`admin/access-templates/${accessTemplate._id}?rev=${accessTemplate._rev}`), {
      headers: getAuthHeaders(),
    })
    .catch(apiErrorCatcher)
}

export async function createAccessTemplate(accessTemplate: AccessTemplate): Promise<AccessTemplate> {
  delete accessTemplate._id
  delete accessTemplate._rev

  return axios
    .post(techdemoApiUrl('admin/access-templates'), accessTemplate, {
      headers: getAuthHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AccessTemplate(data))
    .catch(apiErrorCatcher)
}
